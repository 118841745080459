import { useState } from 'react';
import { Carousel, Col, Container, Row } from 'react-bootstrap';
import Logo from '../../assets/images/header/logo.png';
import Banner from '../../assets/images/header/banner.jpg';

import 'bootstrap/dist/css/bootstrap.min.css';

export default function HeaderSession() {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => setMenuOpen(!isMenuOpen);

  // Links correspondentes
  const menuLinks = [
    { label: 'QUEM SOMOS', url: 'https://www.prosegur.com.br/sobre-prosegur' },
    { label: 'NOSSOS PRODUTOS', url: 'https://www.prosegur.com.br/' },
    { label: 'DEPÓSITO CONTA DIGITAL', url: 'https://www.prosegur.com.br/grandes-empresas/cash-today' },
    { label: 'AJUDA', url: '#' }
  ];

  return (
    <div>
      {/* Header */}
      <Container fluid className="bg-warning">
        <Container>
          <Row className="align-items-center py-3">
            {/* Logo */}
            <Col xs="auto" className="d-flex align-items-center ps-3">
              <img
                src={Logo}
                alt="logo"
                className="img-fluid"
                style={{ width: '220px' }}
              />
            </Col>

            {/* Menu - Desktop */}
            <Col lg="7" className="d-none d-lg-flex">
              <nav className="navmenu d-flex justify-content-between w-100">
                {menuLinks.map((item, index) => (
                  <a
                    key={index}
                    href={item.url}
                    className="mx-2 text-dark text-decoration-none fw-bold"
                  >
                    {item.label}
                  </a>
                ))}
              </nav>
            </Col>

            {/* Botão de Acessar Conta - Desktop */}
            <Col className="d-none d-lg-flex justify-content-end pe-3">
              <button
                className="btn btn-dark"
                style={{ minWidth: '150px' }}
                onClick={() =>
                  window.location.replace('https://prosegurdigital.com.br/')
                }
              >
                Acessar conta
              </button>
            </Col>

            {/* Ícone de Hambúrguer - Mobile */}
            <Col xs="auto" className="d-flex d-lg-none justify-content-end align-items-center pe-3">
              <button
                className="navbar-toggler"
                type="button"
                onClick={toggleMenu}
                style={{ border: 'none', background: 'transparent' }}
              >
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3 6h18M3 12h18M3 18h18"
                    stroke="#000"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </button>
            </Col>
          </Row>
        </Container>

        {/* Menu Responsivo - Mobile */}
        {isMenuOpen && (
          <Row className="d-lg-none bg-warning">
            <Col className="align-items-center">
              <nav className="navmenu d-flex flex-column align-items-start py-3">
                {menuLinks.map((item, index) => (
                  <a
                    key={index}
                    href={item.url}
                    className="py-2 text-dark text-decoration-none"
                    style={{ marginLeft: '20px' }} // Alinhando com a logo
                  >
                    {item.label}
                  </a>
                ))}
                <button
                  className="btn btn-dark my-2"
                  style={{ minWidth: '120px', marginLeft: '20px' }}
                  onClick={() =>
                    window.location.replace('https://prosegurdigital.com.br/')
                  }
                >
                  Acessar conta
                </button>
              </nav>
            </Col>
          </Row>
        )}
      </Container>

      {/* Banner */}
      <section id="banners">
        <Carousel indicators={false} interval={null} controls={false}>
          <Carousel.Item>
            <div
              className="d-flex"
              style={{
                backgroundImage: `url(${Banner})`,
                height: '100vh',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            >
              <Container className="d-flex align-items-center">
                <Row className="w-100">
                  <Col lg="6" className="text-white">
                    <h1 className="display-3 fw-bold">
                      <strong>INOVADOR</strong>, <br />
                      <strong>SIMPLES & </strong>
                      <br /> <strong>SEGURO</strong>
                    </h1>
                  </Col>
                </Row>
              </Container>
            </div>
          </Carousel.Item>
        </Carousel>
      </section>
    </div>
  );
}
