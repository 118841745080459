import { Col, Container, Row } from 'react-bootstrap';

import IphoneIcon from '../../assets/images/servicesSession/iphone.png';

import TransferIcon from '../../assets/images/servicesSession/transferIcon.svg';
import CashIcon from '../../assets/images/servicesSession/cashIcon.svg';
import PaymentIcon from '../../assets/images/servicesSession/paymentIcon.svg';
import ExtractIcon from '../../assets/images/servicesSession/extractIcon.svg';

import 'bootstrap/dist/css/bootstrap.min.css';

export default function ServicesSession() {
  return (
    <div>
      <section id="nova-solucao" className="bg-light py-5">
        <Container>
          <Row>
            <Col lg="6">
              <img
                src={IphoneIcon}
                alt="Nova solução Cash Today"
                className="img-fluid"
              />
            </Col>
            <Col lg="6" className="d-flex mt-3 flex-column align-items-start">
              <h3
                className="fw-bold mt-4"
                style={{ fontSize: '3.5rem', color: 'black' }}
              >
                Conheça os serviços que já estão disponíveis:
              </h3>
              <ul
                className="list-unstyled mt-5 d-flex flex-column"
                style={{ gap: '3.5rem' }}
              >
                <li className="d-flex align-items-center">
                  <img
                    src={CashIcon}
                    alt="Ícone 1"
                    className="me-2"
                    style={{ width: '60px' }}
                  />
                  <span style={{ fontSize: '2rem', color: 'black' }}>
                    Saldo
                  </span>
                </li>
                <li className="d-flex align-items-center">
                  <img
                    src={ExtractIcon}
                    alt="Ícone 2"
                    className="me-2"
                    style={{ width: '60px' }}
                  />
                  <span style={{ fontSize: '2rem', color: 'black' }}>
                    Extrato
                  </span>
                </li>
                <li className="d-flex align-items-center">
                  <img
                    src={PaymentIcon}
                    alt="Ícone 3"
                    className="me-2"
                    style={{ width: '60px' }}
                  />
                  <span style={{ fontSize: '2rem', color: 'black' }}>
                    Pagamentos e geração de boletos
                  </span>
                </li>
                <li className="d-flex align-items-center">
                  <img
                    src={TransferIcon}
                    alt="Ícone 4"
                    className="me-2"
                    style={{ width: '60px' }}
                  />
                  <span style={{ fontSize: '2rem', color: 'black' }}>
                    Transferências e PIX
                  </span>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}
