import { Col, Container, Row } from 'react-bootstrap';
import CashToday from '../../assets/images/cashToday/cashToday.png';

import 'bootstrap/dist/css/bootstrap.min.css';

function CashTodaySession() {
  return (
    <div>
      <section id="nova-solucao" className="bg-light py-5">
        <Container>
          <Row>
            <Col lg="6" className="d-flex align-items-center">
              <div>
                <h2
                  className="fw-bold"
                  style={{ fontSize: '3.8rem', color: 'black' }}
                >
                  Uma <strong>nova solução</strong> <br /> que se integra ao
                  <br />
                  <span className="text-warning">Cash Today</span>
                </h2>
                <p
                  className="text-dark"
                  style={{ fontSize: '1.8rem', color: 'black' }}
                >
                  A <strong> PROSEGUR DIGITAL</strong> oferece um serviço
                  complementar <strong>à solução Cash Today</strong>, que
                  possibilita receber o crédito dos recursos depositados no
                  cofre inteligente
                  <strong> diretamente na sua Conta Digital Prosegur.</strong>
                </p>
              </div>
            </Col>
            <Col lg="6">
              <img
                src={CashToday}
                alt="Nova solução Cash Today"
                className="img-fluid"
              />
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}

export default CashTodaySession;
