import 'bootstrap/dist/css/bootstrap.min.css';

import HeaderSession from './components/HeaderSession/HeaderSession';
import CashTodaySession from './components/CashTodaySession/CashTodaySession.js';
import BenefitsSession from './components/BenefitsSession/BenefitsSession.js';
import ServicesSession from './components/ServicesSession/ServicesSession.js';
import FooterSession from './components/FooterSession/FooterSession.js';

function App() {
  return (
    <div>
      <HeaderSession />
      <CashTodaySession />
      <BenefitsSession />
      <ServicesSession />
      <FooterSession />
    </div>
  );
}

export default App;
