import { Col, Container, Row } from 'react-bootstrap';

import FlexibilityIcon from '../../assets/images/benefits/flexibilityIcon.svg';
import ControlIcon from '../../assets/images/benefits/controlIcon.svg';
import EfficientIcon from '../../assets/images/benefits/efficientIcon.svg';
import IntegrationIcon from '../../assets/images/benefits/integrationIcon.svg';
import AgilityIcon from '../../assets/images/benefits/agilityIcon.svg';

import 'bootstrap/dist/css/bootstrap.min.css';

export default function BenefitsSession() {
  return (
    <div>
      <section id="itens-com-icone" className="py-5 bg-light">
        <Container>
          <Row className="text-center mb-5">
            <Col>
              <h2 className="fw-bold" style={{ fontSize: '3.8rem' }}>
                Vantagens e Benefícios
              </h2>
            </Col>
          </Row>
          <Row className="justify-content-between text-center">
            <Col xs="12" md="4" lg="2" className="mb-4">
              <img
                src={EfficientIcon}
                alt="Ícone 3"
                className="img-fluid mb-3"
                style={{ width: '80px' }}
              />
              <h5>Eficiência</h5>
              <p>
                Os valores depositados no cofre inteligente são creditados
                diariamente em sua conta Prosegur Digital.
              </p>
            </Col>
            {/* Segundo item */}
            <Col xs="12" md="4" lg="2" className="mb-4">
              <img
                src={ControlIcon}
                alt="Ícone 2"
                className="img-fluid mb-3"
                style={{ width: '80px' }}
              />
              <h5>Controle total</h5>
              <p>Gerencie o numerário de todos os seus cofres em um só lugar</p>
            </Col>
            {/* Terceiro item */}

            {/* Primeiro item */}
            <Col xs="12" md="4" lg="2" className="mb-4">
              <img
                src={FlexibilityIcon}
                alt="Ícone 1"
                className="img-fluid mb-3"
                style={{ width: '80px' }}
              />
              <h5>Flexibilidade</h5>
              <p>
                Acesse sua conta Prosegur Digital de onde estiver, pelo
                aplicativo ou Internet banking
              </p>
            </Col>
            {/* Quarto item */}
            <Col xs="12" md="4" lg="2" className="mb-4">
              <img
                src={IntegrationIcon}
                alt="Ícone 4"
                className="img-fluid mb-3"
                style={{ width: '80px' }}
              />
              <h5>Integração</h5>
              <p>
                Tudo em uma só solução: Cofre Inteligente + Conta Prosegur
                Digital + Depósito Diário Prosegur
              </p>
            </Col>
            {/* Quinto item */}
            <Col xs="12" md="4" lg="2" className="mb-4">
              <img
                src={AgilityIcon}
                alt="Ícone 5"
                className="img-fluid mb-3"
                style={{ width: '80px' }}
              />
              <h5>Agilidade</h5>
              <p>
                Com a solução completa da Prosegur, você continua sem se
                preocupar com as coletas. Nós cuidamos de tudo.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}
